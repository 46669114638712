<template>
  <div>
    <home-section
      :theme="isTheme"
      :colors="colors"
      :couple="couple"
      :event="event"
    />
    <couple-section :couple="couple" />
    <event-section
      :year="year"
      :date="date"
      :month="month"
      :hour="hour"
      :minute="minute"
      :event="event"
      :live="live"
      :calendar="calendar"
    />
    <gallery-section
      :videos="videos"
      :couple="couple"
      :dalil="dalil"
      :phone="phone"
      :photos="photos"
      :stories="stories"
      :storySlide="isStorySlide"
    />
    <wishes-section />
    <footer-section :couple="couple" />
    <sound-btn
      :audios="audios"
      @pausing="$emit('pausings')"
      @playing="$emit('playings')"
    />
    <navbar />
  </div>
</template>

<script>
const HomeSection = () => import("@/components/sections/HomeSection.vue");
const CoupleSection = () => import("@/components/sections/CoupleSection.vue");
const WishesSection = () => import("@/components/sections/WishesSection.vue");
const GallerySection = () => import("@/components/sections/GallerySection.vue");
const EventSection = () => import("@/components/sections/EventSection.vue");
const FooterSection = () => import("@/components/sections/FooterSection.vue");
import Navbar from "@/components/Navbar.vue";
import SoundBtn from "@/components/SoundBtn.vue";

export default {
  props: [
    "isTheme",
    "colors",
    "couple",
    "event",
    "year",
    "date",
    "month",
    "hour",
    "minute",
    "live",
    "videos",
    "dalil",
    "phone",
    "photos",
    "stories",
    "isStorySlide",
    "audios",
    "calendar",
  ],
  components: {
    Navbar,
    SoundBtn,
    HomeSection,
    CoupleSection,
    WishesSection,
    GallerySection,
    EventSection,
    FooterSection,
  },
};
</script>