<template>
  <div
    class="
      bg-transparent
      fixed-bottom
      d-flex
      justify-content-end
      pe-2
      pb-1
      mx-auto
    "
    style="bottom: 70px; border: 0; max-width: 480px"
  >
    <button
      @click.prevent="audios.isPlaying ? $emit('pausing') : $emit('playing')"
      class="btn btn-sound"
    >
      <template v-if="audios.isPlaying">
        <VolumeOn width="22" />
      </template>
      <template v-else>
        <VolumeOff width="22" />
      </template>
    </button>
  </div>
</template>

<script>
const VolumeOn = () => import("@/assets/icons/icon-volume-on.svg");
const VolumeOff = () => import("@/assets/icons/icon-volume-off.svg");

export default {
  name: "SoundBtn",
  props: ["audios"],
  components: { VolumeOn, VolumeOff },
};
</script>

<style>
</style>