<template>
  <scrollactive
    :exac="true"
    :duration="100"
    :modify-url="false"
    active-class="active"
    class="nav fixed-bottom nav-justified py-1"
  >
    <a class="nav-link scrollactive-item" href="#homeNav"><HomeIc /> Home</a>
    <a class="nav-link scrollactive-item" href="#couple">
      <RingIc height="25" /> Couple
    </a>
    <a class="nav-link scrollactive-item" href="#event"
      ><CalendarIc height="25" /> Event</a
    >
    <a class="nav-link scrollactive-item" href="#gallery">
      <GalleryIc />Gallery
    </a>
    <a class="nav-link scrollactive-item" href="#wishes"><WishIc />Wishes</a>
  </scrollactive>
</template>

<script>
const HomeIc = () => import("@/assets/icons/icon-home.svg");
const RingIc = () => import("@/assets/icons/icon-ring-nav.svg");
const CalendarIc = () => import("@/assets/icons/icon-calendar.svg");
const GalleryIc = () => import("@/assets/icons/icon-gallery.svg");
const WishIc = () => import("@/assets/icons/icon-wishlist.svg");
export default {
  name: "Navbar",
  components: { HomeIc, RingIc, CalendarIc, GalleryIc, WishIc },
};
</script>
